import logo from './img/stp.png';
import './css/App.css';
import SatelliteSearch from './components/SatelliteSearch.js';
import SatelliteList from './components/SatelliteList';
import SatelliteDetails from "./pages/SatelliteDetails";
import {Helmet} from "react-helmet";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";


function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/satellites/:query" exact component={SatelliteList}/>
                    <Route path="/satellite/:noradId/details" exact component={SatelliteDetails}/>
                </Switch>
            </Router>
        </>
    );
}

const Home = () => (
    <>
        <Helmet>
            <title>Sitara Project Satellite Search</title>
        </Helmet>
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Sitara Project Satellite Search.
                </p>
                <SatelliteSearch/>
            </header>
        </div>
    </>
);

export default App;
