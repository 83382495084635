import React, {Component} from "react";
import '../css/App.css';
import Loader from 'react-loader-spinner';
import {usePromiseTracker} from "react-promise-tracker";
import { withRouter, useLocation} from "react-router-dom";


const LoadingIndicator = props => {
    const {promiseInProgress} = usePromiseTracker();
    return (
        promiseInProgress &&
        <div className="spinner-style">
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100"/>
        </div>
    )
};

const useQuery = props => {
    return new URLSearchParams(useLocation().search);
}

class SatelliteSearch extends Component {
    
    constructor(props) {
        super(props);
        let params = this.props;
        console.log("Params", params);
        this.state = {
            query: params.query,
        }
    }
    

    searchSatellite = async (e) => {
        const query = { firstName: 'John', lastName: 'Doe' };
        e.preventDefault();
        this.props.history.push('/satellites/' + this.state.query);
    };

    render() {

        return (
            <div className="SearchSatellite">
                <form className="form" onSubmit={this.searchSatellite}>
                    <label className="label" htmlFor="query">Satellite Name</label>
                    <input className="input" type="text" name="query"
                        placeholder="i.e. Sentinel"
                        value={this.state.query} onChange={(e) => this.setState({
                            query: e.target.value
                        })}
                    />
                    <button className="button" type="submit">Search</button>
                </form>
            </div>
        );
    }
}

export default withRouter(SatelliteSearch);