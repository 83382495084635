import React, {useState, useEffect} from "react";
import '../css/App.css';
import Nav from "../components/Nav";

function SatelliteDetails({match, location}) {
    useEffect(() => {
        fetchItems();
    }, []);

    const [tleData, setTleData] = useState([]);
    const fetchItems = async () => {
        const data = await fetch('https://tle.ivanstanojevic.me/api/tle/' + match.params.noradId);
        const jsonData = await data.json();
        setTleData(jsonData);
    }
    const data = location.state;
    let date = new Date(data.dateOfLaunch);

    function SourceListItems() {
        return data.sources.map((sourceLink) => <li><a href={sourceLink}>{sourceLink}</a></li>);
    }

    function GetExponential() {
        const num = data.eccentricity;
        return num.toExponential();
    }

    return (
        <div>
            <Nav/>
            <form id="form1">
                <div className="div-table">
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Official Name</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">Other Name</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.officialName}</div>
                        <div className="div-table-col-right">{data.name}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">NORAD #</h4>
                        </div>
                        <div className="div-table-col-center">
                            <h4 className="card--cheader">COSPAR #</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">Date Of Launch</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.noradNumber}</div>
                        <div className="div-table-col-center">{data.cosparNumber}</div>
                        <div className="div-table-col-right">{date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Country Owner</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">UN Registry</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.countryOwner}</div>
                        <div className="div-table-col-right">{data.countryUNRegistry}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Owner Operator</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.ownerOperator}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">User</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">Orbit Class</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.users}</div>
                        <div className="div-table-col-right">{data.orbitClass}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Orbit Type</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">Expected LifeTime</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.orbitType}</div>
                        <div className="div-table-col-left">{data.expectedLifetime}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Purpose</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.purpose}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Purpose Detailed</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.purposeDetailed}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">perigee</h4>
                        </div>
                        <div className="div-table-col-center">
                            <h4 className="card--rheader">apogee</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">eccentricity</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.perigee}</div>
                        <div className="div-table-col-center">{data.apogee}</div>
                        <div className="div-table-col-right">{GetExponential()}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">inclination</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">period</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.inclination}</div>
                        <div className="div-table-col-right">{data.period}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">launch Mass</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">Dry Mass</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.launchMass}</div>
                        <div className="div-table-col-right">{data.dryMass}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Contractor Country</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">Contractor</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.contractorCountry}</div>
                        <div className="div-table-col-right">{data.contractor}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Launch Site</h4>
                        </div>
                        <div className="div-table-col-right">
                            <h4 className="card--rheader">Launch Vehicle</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.launchSite}</div>
                        <div className="div-table-col-right">{data.launchVehicle}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Source Used Orbital</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.sourceUsedOrbital}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">TLE 1</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{tleData.line1}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">TLE 2</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{tleData.line2}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Comments</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{data.comments}</div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col">
                            <h4 className="card--lheader">Sources</h4>
                        </div>
                    </div>
                    <div className="div-table-row">
                        <div className="div-table-col-left">{SourceListItems()}</div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SatelliteDetails;
