import React from "react";
import '../css/App.css';
import {Link} from "react-router-dom";

function SatelliteCard(props){
    const {satellite} = props;
    let date = new Date(satellite.dateOfLaunch);
    return (
        <div className="card">
            <div className="card--content">
                <Link to={{pathname: '/satellite/' + satellite.noradNumber + '/details',
                    state: satellite
                }}>
                    <div className="card--title"><b>{satellite.officialName}</b></div>
                </Link>
                <p><small><b>{satellite.users} [{satellite.orbitClass}]</b> -- {satellite.comments} <b>Purpose:</b> {satellite.purpose}. {satellite.purposeDetailed}</small></p>
                <div className="block_container">
                    <div className="block_left_row"><small><b>Country Owner:</b> {satellite.countryOwner}</small></div>
                    <div className="block_right_row"><small><b>Launch Date:</b>
                        {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}</small></div>
                </div>
                <div className="block_container">
                    <div className="block_left_row"><small><b>COSPAR #:</b> {satellite.cosparNumber}</small></div>
                    <div className="block_right_row"><small><b>NORAD #:</b> {satellite.noradNumber}</small></div>
                </div>
            </div>
        </div>
    );
}

export default SatelliteCard;