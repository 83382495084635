import React, {useState, useEffect} from "react";
import '../css/App.css';
import logo from './../img/stp.png';
import SatelliteCard from "./SatelliteCard";
import {trackPromise} from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import {usePromiseTracker} from "react-promise-tracker";
import ReactPaginate from 'react-paginate';
import { Link, useHistory , withRouter, useLocation} from "react-router-dom";
import { push } from 'react-router-redux';
import qs from 'query-string';
import SatelliteSearch from "./SatelliteSearch";


const LoadingIndicator = props => {
    const {promiseInProgress} = usePromiseTracker();
    return (
        promiseInProgress &&
        <div className="spinner-style">
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100"/>
        </div>
    )
};

const useQuery = props => {
    return new URLSearchParams(useLocation().search);
  }

function SatelliteList({match, location}) {
    let query = useQuery();

    useEffect(() => {
        fetchApi();
    }, []);

    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [satellites, setSatellites] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchApi = async () => {
        const searchUrl = 'http://159.89.136.23:8086/sitara/satellite?query=' + match.params.query
            + '&page=' + currentPage;
        //const searchUrl = 'http://localhost:8083/sitara/satellite?query=' + query;
        try {
            const res = await trackPromise(fetch(searchUrl));
            const data = await res.json();
            if (data.data.totalItems !== 0) {
                setSatellites(data.data.satelliteInfos);
                setPageCount(data.data.totalPages);
                setIsLoaded(true);
            } else {
                setSatellites([]);
            }
            console.log(data);
        } catch (err) {
            console.error(err);
        }
    }

    const handlePageChange = (selectedObject) => {
        setCurrentPage(selectedObject.selected);
        fetchApi();
    };

    return (
        <>
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Sitara Project Satellite Search.
                </p>
                    <SatelliteSearch query={match.params.query}/>
                    <div className="SatelliteList">
                        {isLoaded && (
                            <div className="card-list">
                                <LoadingIndicator />
                                {satellites.map(satellite => (
                                    <SatelliteCard satellite={satellite} key={satellite.id} />
                                ))}
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={pageCount}
                                    pageRange={2}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'container'}
                                    previousLinkClassName={'page'}
                                    breakClassName={'page'}
                                    nextLinkClassName={'page'}
                                    pageClassName={'page'}
                                    disabledClassNae={'disabled'}
                                    activeClassName={'active'}
                                />
                            </div>)}
            </div>
            </header>
        </div>
            </>
    );
}

export default SatelliteList;