import React from "react";
import '../css/App.css';
import logo from '../img/stp.png';

function Nav() {
    return (
       <nav>
           <img src={logo} className="App-logo" alt="logo"/>
       </nav>
    );
}

export default Nav;
